
import projectHuntImg from "static/projects/project-hunt.jpg";
import escapeTheWebImg from "static/projects/escape-the-web.png";
import toffeeblrImg from "static/projects/toffeeblr.png";
import enchantedForestImg from "static/projects/the-enchanted-forest.png";
import weepingCityImg from "static/projects/the-weeping-city.png";
import elpisImg from "static/projects/elpis.png";
import copollaTodoImg from "static/projects/copolla-todo.png";
import vimSubstitutionImg from "static/projects/vim-substitution.png";
import dotfilesImg from "static/projects/dotfiles.png";
import networkingNotesImg from "static/projects/server-and-networking-notes.png";
import poofImg from "static/projects/poof.jpeg";
import happnImg from "static/projects/happn.png";
import cabppImg from "static/projects/cabpp.png";
import bsrImg from "static/projects/bsr.png";
// import saltyCaramelImg from "static/projects/saltyCaramel.png";
import hollarImg from "static/projects/hollar.png";
import soundSocialImg from "static/projects/sound-social.png";
import chessImg from "static/projects/chess.png";
import aslanImg from "static/projects/aslan.png";
import hildaImg from "static/projects/hilda.png";
import troupeImg from "static/projects/troupe.png";
import mixedrealitywindowImg from "static/projects/mixedrealitywindow.png";
import weenixImg from "static/projects/weenix.png";
import sc22Img from "static/projects/sc22.png";
import pokemonGoImg from "static/projects/pokemongo.png";
import ohpcRecipeImg from "static/projects/ohpcrecipe.png";
import storeydbImg from "static/projects/storeydb.png";
import caramelmecchiatoImg from "static/projects/caramelmecchiato.jpg";
import nerfNavigationImg from "static/projects/nerf-navigation.png";
import quadrupedImg from "static/projects/quadruped.png";
import largescalePlanningImg from "static/projects/largescale-planning.png";
import howlRobotImg from "static/projects/howl-robot.png";
import caramelMecchiatoV2Img from "static/projects/caramel-mecchiato-v2.png";
import caramelMecchiatoV3Img from "static/projects/caramel-mecchiato-v3.png";

// import portrait1Img from "static/art/portrait1.png";
// import portrait2Img from "static/art/portrait2.png";
// import portrait3Img from "static/art/portrait3.png";
// import portrait4Img from "static/art/portrait4.png";
// import portrait5Img from "static/art/portrait5.png";
// import portrait10Img from "static/art/portrait10.png";
import portrait12Img from "static/art/portrait12.jpg";
// import portrait13Img from "static/art/portrait13.jpg";
import portrait14Img from "static/art/portrait14.jpg";
import portrait15Img from "static/art/portrait15.jpg";
import portrait16Img from "static/art/portrait16.png";
import urban1Img from "static/art/urban1.png";
import stillLife1Img from "static/art/still-life1.png";
import stillLife2Img from "static/art/still-life2.jpg";

import hornedSkullImg from "static/art/hornedSkull.png";
import desertImg from "static/art/desert.png";
import digitalLandscape from "static/art/digitalLandscape.png";
import digitalCharacter1 from "static/art/digitalCharacter1.png";
import digitalCharacter2 from "static/art/digitalCharacter2.png";
import mhriseImg from "static/art/magnamalo.png";
import mechaImg from "static/art/mecchiato.png";
import howlsImg from "static/art/howls-moving-castle.jpg";

import paperModelImg from "static/art/paperModel.png";
import mechaRhinoImg from "static/art/mechaRhino.png";
// import strandbeestsImg from "static/art/strandbeests.png";
import mechaPosterImg from "static/art/mecha-poster.png";
import mechaWalkingImg from "static/art/mecha-walking.png";

export const MY_ART = [

  { thumbnail: portrait16Img, col: 0 }, 
  { thumbnail: portrait12Img, col: 0 }, 
  { thumbnail: portrait14Img, col: 0 }, 
  { thumbnail: portrait15Img, col: 0 }, 
  { thumbnail: stillLife1Img, col: 0 },
  { thumbnail: stillLife2Img, col: 0 },

  { thumbnail: howlsImg, col: 1 }, 
  { thumbnail: urban1Img, col: 1 }, 
  { thumbnail: mechaPosterImg, col: 1 }, 
  { thumbnail: hornedSkullImg, col: 1 }, 
  { thumbnail: desertImg, col: 1 }, 

  // { thumbnail: strandbeestsImg, col: 2 }, 
  { thumbnail: mechaWalkingImg, col: 2 }, 

  { thumbnail: mechaRhinoImg, col: 2 }, 
  { thumbnail: mechaImg, col: 2 }, 
  { thumbnail: paperModelImg, col: 2 }, 
  { thumbnail: mhriseImg, col: 2 }, 
  { thumbnail: digitalCharacter2, col: 2 }, 
  { thumbnail: digitalCharacter1, col: 2 }, 
  { thumbnail: digitalLandscape, col: 2 }, 

  // { thumbnail: portrait1Img, col: 0 }, 
  // { thumbnail: portrait3Img, col: 0 }, 
  // { thumbnail: portrait5Img, col: 1 }, 
  // { thumbnail: portrait2Img, col: 2 }, 
  // { thumbnail: portrait4Img, col: 2 }, 
];

export const MY_CATEGORIES = ["featured", "robotics", "systems", "visualcomputing", "fullstack", "gamedev", "all"];
export const MY_CATEGORIES_MOBILE = ["featured", "robotics", "systems", "visualcomputing", "fullstack", "gamedev", "all"];
export const MY_PROJECTS = [
	{
		title: "Largescale Multiagent Planning",
		description: "Hierarchical Large Scale Multirobot Path (Re)Planning. Multi-threaded (30) real-time processing, with 142 nanoquadrotors.\nPublished in IROS 24",
		url: "https://ieeexplore.ieee.org/abstract/document/10801655",
		thumbnail: largescalePlanningImg,
		tags: ["C++", "ROS", "OpenMP", "High Performance Computing"], 
		categories: ["featured", "robotics", "systems"],
		col: 0
	},
	{
		title: "Neural Radiance Field Navigation",
		description: "Online scene reconstruction with I-NGP and RRT* planning algorithm, to achieve collision-free navigation with Boston Dynamics' Spot.",
		url: "https://github.com/kaisucode/nerf-navigation",
		thumbnail: nerfNavigationImg,
		tags: ["Python", "Pytorch", "Machine Learning"], 
		categories: ["robotics", "visualcomputing"],
		col: 1

	},
	{
		title: "Howl's Moving Castle",
		description: "Kinematic sculpture, powering Howl's Moving Castle from Studio Ghibli with Theo Jansen's Strandbeest linkage design for organic walking movement.",
		url: "",
		thumbnail: howlRobotImg,
		tags: ["Fusion360", "Raspberry Pi Pico"], 
		categories: ["robotics"],
		col: 2
	},
	{
		title: "Caramel Mecchiato V3",
		description: "[WIP] Four-bar linkage mechanism legs with ball bearing shoulder joints, uses ros_control and MicroROS over WiFi for servo motion smoothening. Takes PS5 controller input.",
		url: "https://github.com/kaisucode/caramel-mecchiato-ros",
		thumbnail: caramelMecchiatoV3Img,
		tags: ["C++", "ROS", "ESP32", "Docker"], 
		categories: ["robotics"],
		col: 0
	},
	{
		title: "Caramel Mecchiato V2",
		description: "Shadow human motion (including depth) using single camera computer vision processing, custom movement scheduler and interpolation. Each leg has 5 DoF and can take one and a half steps before falling",
		url: "https://github.com/kaisucode/mecha-code",
		thumbnail: caramelMecchiatoV2Img,
		tags: ["Python", "Bluetooth", "Raspberry Pi Pico"], 
		categories: ["robotics"],
		col: 1
	},
  {
    title: "Caramel Mecchiato",
    description: "A voice activated mecha project. 3D modeled and printed by myself! 6 iterations were created, with modular arms and attachments that can be swapped out for each other.",
    url: "https://github.com/kaisucode/caramel-mecchiato",
    thumbnail: caramelmecchiatoImg,
    tags: ["Python", "Flask", "3D Printing"], 
    categories: ["featured", "robotics"],
    col: 2
  }, 
	{
		title: "Quadruped",
		description: "Quadruped with 8 servos. Pi Pico has 8 state machines. Thought I could use a state machine per servo, turns out Bluetooth takes up one, added hot-swapping for state machine usage.",
		url: "https://github.com/kaisucode/mecha-code",
		thumbnail: quadrupedImg,
		tags: ["Python", "Bluetooth", "Raspberry Pi Pico"], 
		categories: ["robotics"],
		col: 0
	},
  {
    title: "Troupe",
    description: "An augmented realty app that allows users to create special effects with gestures .\nCo-developed with Mandy He, Sophia Liu, and Tianran Zhang.",
    url: "https://github.com/kaisucode/troupe",
    thumbnail: troupeImg,
    tags: ["TensorFlow", "OpenCV", "Python"], 
    categories: ["visualcomputing"],
    col: 0
  }, 
  {
    title: "Weenix",
    description: "Created as a semester-long project for Brown’s CSCI 1690: Operating Systems Lab. Built an OS kernel based on Unix in C, with processes, threads, file system (S5FS), and device driver functionalities.",
    url: "https://github.com/brown-cs1690/handout/wiki",
    thumbnail: weenixImg,
    tags: ["C", "Unix"], 
    categories: ["systems", "featured"],
    col: 2
  },
  {
    title: "Hilda",
    description: "A stylized 3D scenery that contains terrains generated with Fractional Brownian Motion and Perlin Noise, as well as orbit camera movement and randomly spawned trees.\nCo-developed with Mandy He and Mehek Jethani.",
    thumbnail: hildaImg,
    url: "https://github.com/kaisucode/hilda",
    tags: ["OpenGL", "C++"], 
    categories: ["visualcomputing"],
    col: 1
  }, 
  {
    title: "GPU Benchmarking on HPC",
    description: "Research project on creating a benchmarking tool, for GPU and distributed training on high performance computing clusters. ",
    url: "https://github.com/kaisucode/containerized-tf-benchmarking",
    thumbnail: sc22Img,
    tags: ["High Performance Computing", "Python", "TensorFlow", "Singularity"], 
    categories: ["systems", "featured"],
    col: 1
  }, 
  {
    title: "OpenHPC Container Recipe",
    description: "OpenHPC recipe for NVIDIA's container maker.",
    url: "https://github.com/kaisucode/ohpc-container-recipe/tree/sc22",
    thumbnail: ohpcRecipeImg,
    tags: ["High Performance Computing", "Python", "Singularity", "NVIDIA HPCCM"], 
    categories: ["systems"],
    col: 1
  }, 
  {
    title: "Mixed Reality Window",
    description: "Prototyped real-time communication & networking software, using machine learning solutions and OpenCV to process camera feed and extract selfie-view videos.\nPublished in Media Architecture Biennale 23.",
    url: "https://dl.acm.org/doi/abs/10.1145/3627611.3627629",
    thumbnail: mixedrealitywindowImg,
    tags: ["OpenCV", "Python", "aiortc", "Docker"], 
    categories: ["visualcomputing"],
    col: 0
  }, 
  {
    title: "Courses @ Brown ++",
    description: "C@B prerequisite/track visualization: a directed graph of courses where prerequisites lead to higher level courses, with a recommendation system that suggests courses similar to ones users have taken.",
    url: "https://github.com/courses-at-brown-plus-plus/cab-plus-plus",
    thumbnail: cabppImg,
    tags: ["Flask", "React", "Redux", "Python", "JavaScript"], 
    categories: ["fullstack"],
    col: 2
  },
  {
    title: "Escape the Web",
    description: "Fall through the internet while using shady ads to destroy glitches.\nCreated as a member of Brown / RISD Game Developers",
    url: "https://brownrisdgames.itch.io/brgd-y2k",
    thumbnail: escapeTheWebImg,
    tags: ["Unity", "C#"], 
    categories: ["gamedev"],
    col: 0
  }, 
  {
    title: "Poof the Floof",
    description: "Poof the sheep impostors in this 2D rapid-fire clicking game.\nCreated as a member of Brown / RISD Game Developers",
    url: "https://brownrisdgames.itch.io/dreams",
    thumbnail: poofImg,
    tags: ["Unity", "C#"], 
    categories: ["gamedev"],
    col: 0
  },
  { 
    title: "Toffeeblr", 
    description: "Social media platform built with ORM and Ruby on Rails, based on Tumblr", 
    url: "https://github.com/kaisucode/toffeeblr-server.", 
    thumbnail: toffeeblrImg,
    tags: ["Rails", "React", "Redux", "JWT", "Ruby", "JavaScript"], 
    categories: ["fullstack"],
    col: 0
  }, 
  {
    title: "Project Elpis",
    description: "Adventure game made with Godot, inspired by Pandora and her box.\nCo-developed with Katherine Hsu.",
    url: "https://github.com/neverlandiz/elpis",
    thumbnail: elpisImg,
    tags: ["Godot", "GDScript"], 
    categories: ["gamedev"],
    col: 0
  },
  // {
  //   title: "Brown Student Radio App",
  //   description: "app",
  //   url: "#",
  //   thumbnail: bsrImg,
  //   tags: ["React Native", "Expo", "JavaScript"], 
  //   col: 0
  // },
  {
    title: "StoreyDB",
    description: "Tool for enabling the development of an open source story. Co-developed with Alek Westover",
    url: "https://github.com/awestover/storeydb",
    thumbnail: storeydbImg,
    tags: ["React", "Golang"], 
    categories: ["fullstack"],
    col: 1
  },
  {
    title: "Hollar",
    description: "An online ordering platform for small businesses and restaurants. \nCreated during Hack@Brown with Nathan Luu and Sreehari Rammohan",
    url: "https://github.com/hollar-app/hollar-app",
    thumbnail: hollarImg,
    tags: ["React", "Next.js", "Google Firebase", "JavaScript"], 
    categories: ["fullstack"],
    col: 1
  },
  // {
  //   title: "Salty Caramel",
  //   description: "An Extremely Salty Mobile App that yells at you with your reminders",
  //   url: "https://github.com/kaisucode/salty-caramel",
  //   thumbnail: saltyCaramelImg,
  //   tags: ["React Native", "Expo", "JavaScript"], 
  //   col: 1
  // },
  {
    title: "The Enchanted Forest",
    description: "RPG made with a custom game engine, and a login system.\nCo-developed with Alek Westover.",
    url: "https://github.com/awestover/enchantedForest",
    thumbnail: enchantedForestImg,
    tags: ["Flask", "p5", "MongoDB", "Python", "JavaScript"],
    categories: ["gamedev"],
    col: 1
  },
  {
    title: "The Weeping City",
    description: "Fantasy-style tower defense game created in Javscript with a custom game engine.\nCo-developed with Alek Westover.",
    url: "https://github.com/kaisucode/the_weeping_city",
    thumbnail: weepingCityImg,
    tags: ["p5", "JavaScript"],
    categories: ["gamedev"],
    col: 1
  },
  {
    title: "Pokemon GOlang",
    description: "Terminal-based Pokemon game, created in golang. Not the AR game. ",
    url: "https://github.com/kaisucode/pokemon-GOlang",
    thumbnail: pokemonGoImg,
    tags: ["Go"], 
    categories: ["systems", "gamedev"],
    col: 2
  },
  {
    title: "Project Hunt",
    description: "Social networking site that allows users to recruit members for their projects.\n Created during Hack@Home with Nathan Luu, Nayoung Choi, and Michael Donoso",
    url: "https://github.com/nthnluu/project-hunt",
    thumbnail: projectHuntImg,
    tags: ["Google Firebase", "React", "Next.js", "JavaScript"], 
    categories: ["fullstack"],
    col: 2
  }, 
  {
    title: "Soundsocial-Online",
    description: "A personal knowledge management & audio-based social media tool to help users curate, and share spoken text audio. \nCreated during HackUMass with Sreehari Rammohan",
    url: "https://github.com/SreehariRamMohan/soundsocial-frontend",
    thumbnail: soundSocialImg,
    tags: ["Flask", "Google Cloud Platform", "Python", "MongoDB"], 
    categories: ["fullstack", "featured"],
    col: 2
  },
  {
    title: "Server & Networking Notes",
    description: "My notes on CentOS servers and Cisco switches, including network configurations, NIS, and NFS setup",
    // url: "/assets/kevin-hsu-server-and-networking-notes.pdf",
    url: "https://drive.google.com/file/d/1N4U-GwUu5j7XWbnV_vOrki82SdVLq5oq/view?usp=sharing",
    thumbnail: networkingNotesImg,
    tags: ["Linux", "Bash"], 
    categories: ["systems", "featured"],
    col: 2
  },
  {
    title: "vim-substitution", 
    description: "Vim plugin to easily replace text. Installable with vim-plug!",
    url: "https://github.com/kaisucode/vim-substitution",
    thumbnail: vimSubstitutionImg, 
    tags: ["Vimscript"], 
    categories: ["systems"],
    col: 2
  },
  {
    title: "Copolla Todo",
    description: "Todo app with vim keybindings (yes another vim-esque tool), built with Vue and Electron.\nCo-developed with Alek Westover.",
    url: "https://github.com/kaisucode/copolla-todo",
    thumbnail: copollaTodoImg,
    tags: ["Electron", "Vue", "Vuex", "JavaScript"],
    categories: ["fullstack"],
    col: 2
  },
  {
    title: "Aslan",
    description: "A convolutional neural network model to recognize the letters of the American Sign Language alphabet. \nCo-developed with Emily Ye and Gareth Mansfield.",
    url: "https://github.com/emxly-21/aslan",
    thumbnail: aslanImg,
    tags: ["TensorFlow", "Keras", "OpenCV"], 
    categories: ["visualcomputing"],
    col: 1
  }, 
  {
    title: "Chess",
    description: "Chess game built with C++ and SFML",
    url: "https://github.com/kaisucode/chess",
    thumbnail: chessImg,
    tags: ["C++", "SFML"], 
    categories: ["gamedev"],
    col: 1
  },
  {
    title: "Happn",
    description: "Making friendships Happn. \nCreated during HackPrinceton with Sreehari Rammohan, Gareth Mansfield, and Emily Ye",
    url: "https://github.com/SreehariRamMohan/happn-fe",
    thumbnail: happnImg,
    tags: ["Flask", "Websockets", "Machine Learning", "Google Cloud Platform", "MongoDB", "React", "JavaScript", "Python"], 
    categories: ["fullstack"],
    col: 0
  },
  {
    title: "My Dotfiles", 
    description: "zsh, neovim, aliases, git, and more configs",
    url: "https://github.com/kaisucode/dotfiles-macOS",
    thumbnail: dotfilesImg,
    tags: ["Bash", "Git", "Vimscript"], 
    categories: ["systems"],
    col: 2
  },
  // {
  //   title: "Velaris",
  //   description: "Music player with Vim keybindings, created in Javascript and Electron. Includes todo list marquees, quote displays, and other widgets.",
  //   thumbnailUrl: "/img/thumbnails/undef.jpg",
  //   githubUrl: "https://github.com/kaisucode/musicPlayer",
  // },
  //
  // spotify-snapshot, parkingLotBand, pyret-blocks
];

export const MY_SKILLS = {
  Languages: ["JavaScript", "Python", "C++", "C", "Java", "C#", "Go", "TypeScript", "Matlab", "Bash", "PHP"],
  Frontend: ["React", "React Native", "Next.js", "Redux", "Vue", "Vuex", "SCSS", "HTML", "CSS"],
  Backend: ["Flask", "Ruby on Rails", "Express", "Spring", "MongoDB", "MySQL", "PostgreSQL", "SQLite"],
  DevOps: ["Apache", "Nginx", "Digital Ocean", "GCP", "AWS", "Git", "Docker", "Vagrant", "VirtualBox", "Kubernetes", "Terraform"],
  Tools: ["TensorFlow", "Keras", "OpenCV", "Unity", "OpenGL", "SFML", "GDB", "Raspberry Pi", "Vim"]
};

export const MY_EXPERIENCES = [
  { date: "2024", 
    title: "Software Engineer, ML Foundations",
    content: "Stripe"
  },
  { date: "2023", 
    title: "Site Reliability Engineer Intern",
    content: "Dropbox"
  },
  { date: "2022", 
    title: "Software Engineer Intern",
    content: "NASA Ames Research Center"
  },
  { date: "2022", 
    title: "OpenHPC (High Performance Computing) Mentee",
    content: "The Linux Foundation"
  },
  { date: "2022", 
    title: "Human-Computer Interaction Lab",
    title1: "Automatic Coordination of Teams Lab",
    content: "Brown University Research Assistant"
  },
  { date: "2021-2022", 
    title: "CSCI 0320 Software Engineering", 
    title1: "ENGN 0032 Engineering: Design", 
    content: "Brown University Teaching Assitant" }, 
  { date: "2020-2021", title: "Unity Programmer", content: "Brown RISD Game Developers" }, 
  // { date: "2020-2021", title: "Web Developer & Graphic Designer", content: "Serene Beauty Boutique" }, 
  // { date: "2020", title: "Lead Software Engineer", content: "Cop or Drop" },
  // { date: "2019", title: "Engineer Intern", content: "SunMedia Technology Co., LTD", last: true }
];

// export const MY_EXPERIENCES = [
//   { date: "2021", title: "ENGN 0032 Engineering: Design Teaching Assistant", content: "Brown University" }, 
//   { date: "2020-2021", title: "Fullstack Engineer", content: "Full Stack at Brown" }, 
//   { date: "2020-2021", title: "Unity Programmer", content: "Brown RISD Game Developers" }, 
//   { date: "2020", title: "Web Developer & Graphic Designer", content: "Serene, Taipei, Taiwan" }, 
//   { date: "2020", title: "Lead Software Engineer", content: "Cop or Drop, Boston, MA, U.S.A." },
//   { date: "2019", title: "Summer Intern", content: "SunMedia Technology Co., LTD, Chengdu, China", last: true }
// ];

