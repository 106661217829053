import React from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ContactInfo from '../components/ContactInfo';
import profileImg from "static/me/sc23.jpg";


export default function LandingPage() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function renderProfilePic() {
    return (
      <Box width={(isMobile) ? "100%" : "24vw"} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Paper elevation={3} style={{ backgroundColor: "transparent", height: "20rem" }}>
          {
            <img src={profileImg} height="100%" style={{borderRadius: "8px"}}/>
            // <Paper elevation={3} style={{ backgroundColor: "grey", height: "18rem", width: "14rem" }}>
            // <Paper variant="outlined">
            // (image will be uploaded soon)
          }
        </Paper>

        {(!isMobile) &&
          <Box mt={3}>
            <Typography variant="h6" color="primary">
				powered by chocolates and <span style={{ textDecoration: "line-through" }}>caffeine</span> decaf
            </Typography>
          </Box>
        }
        <br/>
      </Box>
    );
  }

  return (
    <Box 
      display="flex" 
      flexDirection={(isMobile) ? "column" : "row"} 
      justifyContent="center" 
      alignItems="center" 
      minHeight={(isMobile) ? "120vh" : "82vh"}
      width={(isMobile) ? "90%" : "100%"}
      m="0 auto"
    >

      {(isMobile)
        ? <TextIntro isMobile={isMobile}>
          <Box width="8vw" height="3vh"/>
          {renderProfilePic()}
          <br/>
        </TextIntro>
        : <>
          <TextIntro isMobile={isMobile}/>
          <Box width="8vw" height="8vh"/>
          {renderProfilePic()}
        </>
      }

    </Box>

  );
}

const TextIntro = (props) => {
  return (
    <Box>
      <Typography 
        variant={(props.isMobile) ? "h4" : "h6"} 
        align={(props.isMobile) ? "center" : "left"}
        color="primary" 
        gutterBottom>
        Welcome To My Website!
        *waves excitedly*
      </Typography>

      { (!props.isMobile) && <br /> }

      <Typography 
        variant={(props.isMobile) ? "h6" : "h1"} 
        align={(props.isMobile) ? "center" : "left"}
        color="textSecondary" 
        gutterBottom>
        I'm Kevin Hsu
      </Typography>

      { props.children }

      <Typography 
        variant={(props.isMobile) ? "body2" : "h6"} 
        align={(props.isMobile) ? "center" : "left"}
        color="secondary" 
        gutterBottom
      > 
        { 
          (props.isMobile) ?
            <>
				Recently graduated from Brown University, where I studied computer science and visual art.
				Nowadays I build robotics for fun, and ML infra for work.
            </>
            : 
            <>
				Recently graduated from Brown University, <br/>
				where I studied computer science and visual art.<br/>
				Nowadays I build robots for fun, and ML infra for work<br/>
            </>
        }
        {
          // <br/> <br/>
          // When I'm not writing code, I indulge in my obsessions for reading, drawing, skateboarding, and eating too much chocolate.
        }
      </Typography>

      <br />

      <ContactInfo size="large" color="primary" />
    </Box>
  );
}


